// import the Swiper library
import Swiper, { Navigation } from 'swiper';

import 'swiper/swiper-bundle.css';

const KEY_DOWN_EVENT = 'keydown';

Swiper.use([Navigation]);

// get a reference to the swiper container element
const swiperContainer = document.querySelector('.swiper-container');

const arrowSelector = '.gallery-arrow';
const arrowElements = document.querySelectorAll(arrowSelector);

// initialize the swiper instance
const swiper = new Swiper(swiperContainer, {
  loop: true,
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
      navigation: {
        nextEl: `${arrowSelector}.swiper-button-next`,
        prevEl: `${arrowSelector}.swiper-button-prev`,
      },
    },
  },
  autoplay: {
    delay: 5000,
  },
  speed: 1000,
  zoom: true,
});

const isFullScreenClass = 'is-fullscreen';

const escTurnsFullScreenOff = (e) => e.key === 'Escape' && toggleFullScreen(false);
const moveUsingKeyboard = (e) => {
  e.key === 'ArrowLeft' && swiper.slidePrev();
  e.key === 'ArrowRight' && swiper.slideNext();
}

const toggleFullScreen = (isEnable) => {
  const isEnableFullScreen = isEnable === true || !document.body.classList.contains(isFullScreenClass);

  document.body.classList.toggle(isFullScreenClass, isEnableFullScreen);
  swiper.params.slidesPerView = isEnableFullScreen ? 1 : swiper.params.breakpoints[swiper.currentBreakpoint].slidesPerView;

  if (isEnableFullScreen) {
    document.addEventListener(KEY_DOWN_EVENT, escTurnsFullScreenOff);
    document.addEventListener(KEY_DOWN_EVENT, moveUsingKeyboard);
    arrowElements.forEach(arrow => swiperContainer.appendChild(arrow));
  }
  else {
    document.removeEventListener(KEY_DOWN_EVENT, escTurnsFullScreenOff);
    document.removeEventListener(KEY_DOWN_EVENT, moveUsingKeyboard);
    swiper.slideTo(swiper.activeIndex - 1, 0);
    arrowElements.forEach(arrow => swiperContainer.parentNode.appendChild(arrow));
  }
};

swiperContainer.querySelectorAll('img').forEach((img, index) => {
  img.addEventListener('click', () => {
    if (document.body.classList.contains(isFullScreenClass)) return;
    swiper.slideTo(index, 0);
    toggleFullScreen();
  });
});

const closeBtn = swiperContainer.querySelector('.swiper-button-close');
closeBtn.addEventListener('click', () => toggleFullScreen(false));